import { AppDispatch } from "../../configureStore";
import { ComparisonButtonType, ComparisonConfig, HighlightConfig, HighlightTabType, ITab, ReportTabType } from "../../interfaces";
export const CHANGE_TAB_TITLE = 'tabs/CHANGE_TAB_TITLE';
export const CHANGE_TAB_TYPE = 'tabs/CHANGE_TAB_TYPE';

export const REMOVE_TAB = 'tabs/REMOVE_TAB';
export const ADD_TAB = 'tabs/ADD_TAB';

export const CHANGE_HIGHLIGHT_TYPE = 'tabs/CHANGE_HIGHLIGHT_TYPE';
export const CHANGE_COMPARISON_TYPE = 'tabs/CHANGE_COMPARISON_TYPE';

export const SET_IS_PRINT = 'tabs/SET_IS_PRINT';

export const SET_IS_EXCEL = 'tabs/SET_IS_EXCEL';

const initialState: {
    tabs: ITab[];
    isPrint: boolean;
    isExcel: boolean;
} = {
    isPrint: false,
    isExcel: false,
    tabs: [
        {
            type: 'Title',
            title: 'Title'
        },
        {
            type: 'Highlights',
            title: 'Highlights',
            config: {
                type: 'Highlights',
                details: [
                    'Loans / Orders',
                    'Borrowers',
                    'Transcripts',
                    'Loan Processors',
                    'Avg Processing Time',
                    'Success Rate',
                ]
            },
        },
        {
            type: 'Comparison',
            title: 'Comparison',
            config: {
                type: 'Comparison',
                details: {
                    "Loan Professionals": true,
                    Borrowers: true,
                    Loans: true,
                    Transcripts: true,
                }
            },
        },
        {
            type: 'Completion',
            title: 'Completion',
        },
        {
            type: 'Action',
            title: 'Action',
        },
        {
            type: 'Orders',
            title: 'Orders',
        },
    ],
}

type ActionParams =
    | { type: typeof CHANGE_TAB_TITLE; index: number; value: string; }
    | { type: typeof CHANGE_TAB_TYPE; index: number; value: ReportTabType; }
    | { type: typeof REMOVE_TAB; index: number; }
    | { type: typeof ADD_TAB; index: number; }
    | { type: typeof CHANGE_HIGHLIGHT_TYPE; tabIndex: number; index: number; newType: HighlightTabType; }
    | { type: typeof CHANGE_COMPARISON_TYPE; tabIndex: number; name: ComparisonButtonType; newValue: boolean; }
    | { type: typeof SET_IS_PRINT; isPrint: boolean; }
    | { type: typeof SET_IS_EXCEL; isExcel: boolean; };

function reducer(state = initialState, action: ActionParams): typeof initialState {
    switch (action.type) {
        case SET_IS_PRINT: {
            return {
                ...state,
                isPrint: action.isPrint,
            }
        }

        case SET_IS_EXCEL: {
            return {
                ...state,
                isExcel: action.isExcel,
            }
        }

        case CHANGE_COMPARISON_TYPE: {
            const changeTabs: ITab[] = JSON.parse(JSON.stringify(state.tabs));
            if ((changeTabs?.[action.tabIndex]?.config?.details as any)) {
                (changeTabs[action.tabIndex].config as ComparisonConfig).details[action.name] = action.newValue;
                return {
                    ...state,
                    tabs: changeTabs,
                }
            }

            return state;
        }

        case CHANGE_HIGHLIGHT_TYPE: {
            const changeTabs: ITab[] = JSON.parse(JSON.stringify(state.tabs));
            if ((changeTabs?.[action.tabIndex]?.config?.details as any)?.[action.index]) {
                (changeTabs[action.tabIndex].config as HighlightConfig).details[action.index] = action.newType;
                return {
                    ...state,
                    tabs: changeTabs,
                }
            }

            return state;
        }

        case ADD_TAB: {
            return {
                ...state,
                tabs: [...state.tabs, { type: 'Unassigned', title: 'New Tab' }]
            }
        }

        case REMOVE_TAB: {
            const removedTabs = JSON.parse(JSON.stringify(state.tabs));
            removedTabs.splice(action.index, 1);

            return {
                ...state,
                tabs: removedTabs,
            }
        }

        case CHANGE_TAB_TYPE: {
            const newType = JSON.parse(JSON.stringify(state.tabs));
            newType[action.index].type = action.value;
            switch (action.value) {
                case "Highlights":
                    newType[action.index].config = {
                        details: [
                            'Loans / Orders',
                            'Borrowers',
                            'Transcripts',
                            'Loan Processors',
                            'Avg Processing Time',
                            'Success Rate',
                        ],
                        type: "Highlights"
                    } as HighlightConfig;
                    break;
                case "Comparison":
                    newType[action.index].config = {
                        details: {
                            'Loan Professionals': true,
                            Loans: true,
                            Borrowers: true,
                            Transcripts: true,
                        },
                        type: "Comparison",
                    } as ComparisonConfig
                    break;
                default:
                    newType[action.index].config = undefined;
                    break;
            }

            return {
                ...state,
                tabs: newType,
            }
        }

        case CHANGE_TAB_TITLE: {
            const newTitle = JSON.parse(JSON.stringify(state.tabs));
            newTitle[action.index].title = action.value;

            return {
                ...state,
                tabs: newTitle,
            }
        }

        default: {
            return state;
        }
    }
}

export const changeComparisonValue = ({ tabIndex, newValue, name }: { tabIndex: number; name: ComparisonButtonType; newValue: boolean; }) => (dispatch: AppDispatch) => {
    dispatch({ type: CHANGE_COMPARISON_TYPE, tabIndex, name, newValue })
}

export default reducer;
