import { AppDispatch } from "../../configureStore";
import { ITab, SaveStats } from "../../interfaces";
import { AllStats, SupportStats } from "../../interfaces/report";
import execute from "../http/execute";
import failure from "../http/failure";
import { getNoAuth } from "../http/request";
import success from "../http/success";

export const GET_DATA_REQUEST = 'client/GET_DATA_REQUEST';
export const GET_DATA_SUCCESS = 'client/GET_DATA_SUCCESS';
export const GET_DATA_FAILURE = 'client/GET_DATA_FAILURE';

const initialState: {
    stats: AllStats;
    supportStats: SupportStats;
    tabs: ITab[];
    useName: string;
    dateRange: {
        startDate: string;
        endDate: string;
    }
} = {
    stats: {
        allRequests: 0,
        highlights: {
            None: 0,
            "Loans / Orders": 0,
            Borrowers: 0,
            Transcripts: 0,
            "Loan Processors": 0,
            "Avg Processing Time": 0,
            "Success Rate": 0,
        },
        monthly: [],
        completion: {
            period: 'day',
            data: [],
        },
        actionReasons: [],
        orderType: {
            no: 0,
            yes: 0,
        },
        processors: [],
    },
    supportStats: {
        breakdown: [],
        byIssue: {},
        byEmployee: {},
    },
    tabs: [],
    dateRange: {
        startDate: '',
        endDate: '',
    },
    useName: '',
}

type ActionParams =
    | { type: typeof GET_DATA_SUCCESS; data: SaveStats; };

function reducer(state = initialState, action: ActionParams): typeof initialState {
    switch (action.type) {
        case GET_DATA_SUCCESS:
            return {
                ...state,
                stats: action.data.stats,
                supportStats: action.data.supportStats,
                tabs: action.data.tabs,
                useName: action.data.useName || action.data.institutionName,
                dateRange: action.data.dateRange,
            }

        default: return state
    }
}

export const getSaved = (payload: { institutionName: string | null; summary: string | null; }) => (dispatch: AppDispatch) => {
    execute(getNoAuth, '/saved', payload)
        .then(success(dispatch, GET_DATA_SUCCESS, payload))
        .catch(failure(dispatch, GET_DATA_FAILURE, payload));

    dispatch({
        type: GET_DATA_REQUEST,
        payload,
    });
};

export default reducer;
