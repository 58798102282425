import { Box, Typography } from "@mui/material";
import SmallBar from "../../atoms/SmallBar";
import globalStyles from "../../../globalStyles";
import { useSelector } from "react-redux";
import { RootState } from "../../../configureStore";
import { StringTotal } from "../../../interfaces/report";

interface ActionParams {
    index: number;
}

const Action = ({
    index,
}: ActionParams) => {
    const actions = useSelector<RootState, StringTotal[]>(({ stats: { stats: { actionReasons } } }) => actionReasons);

    return (
        <div style={{ height: '100%', flexDirection: 'column', display: 'flex' }}>
            <SmallBar type="Action" />
            {actions.length ? (
                <Box sx={globalStyles.borderBox}>
                    <ul>
                        {actions.map((ar, idx) => (
                            <li key={`ar-${index}-${idx}`}>
                                <Typography fontSize={24} textTransform='uppercase'>
                                    {ar.name} - {ar.total}
                                </Typography>
                            </li>
                        ))}
                    </ul>
                </Box>
            ) : (
                <Box sx={[globalStyles.flexCenterCenter, { height: '70%' }]}><Typography fontSize={24} textTransform='uppercase'>No Action Needed in Selected Time</Typography></Box>
            )}
        </div>
    );
};

export default Action;
