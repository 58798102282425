import { Box, Grid } from "@mui/material";
import SmallBar from "../../atoms/SmallBar";
import UsageBox from "../../molecules/UsageBox";
import globalStyles from "../../../globalStyles";
import { useSelector } from "react-redux";
import { RootState } from "../../../configureStore";
import { HighlightConfig } from "../../../interfaces";
import { useMemo } from "react";
import { HighlightStats, UsageBoxParams } from "../../../interfaces/report";

interface HighlightsParams {
    index: number;
}

const Highlights = ({
    index,
}: HighlightsParams) => {
    const titles = useSelector<RootState, HighlightConfig | undefined>(({ tabs: { tabs } }) => tabs[index]?.config as HighlightConfig);
    const highlights = useSelector<RootState, HighlightStats>(({ stats: { stats: { highlights: hl } } }) => hl);

    const arr = useMemo<UsageBoxParams[]>(() => (
        titles
            ? titles.details.filter((deet) => deet !== 'None').map<UsageBoxParams>((typeName) => ({
                isPercent: typeName === 'Success Rate',
                name: typeName,
                value: highlights[typeName],
            }))
            : []
    ), [
        titles,
        highlights
    ]);

    return (
        <div style={{ height: '100%', flexDirection: 'column', display: 'flex', justifyContent: 'space-between' }}>
            <SmallBar type="Highlights" />
            <Box sx={[{ height: '80%' }, globalStyles.mx4, globalStyles.mt2]}>
                <Grid sx={{ height: '94%' }} justifyContent="space-around" container spacing={1}>
                    {arr.map(({ isPercent, name, value }, idx) => (
                        <Grid
                            key={`hghlts-${idx}`}
                            item
                            xs={4}
                        >
                            <UsageBox isPercent={isPercent} name={name} value={value} />
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </div>
    );
};

export default Highlights;
